import Frame from "./Frame"
import icon_noti from "../../assets/svg/icon_noti.svg"
import icon_refresh from "../../assets/svg/icon_refresh.svg"
import icon_w3p_logo from "../../assets/svg/logo.svg"
import icon_lpoint from "../../assets/icon_lpoint.png"
import icon_disabled from "../../assets/icon_disabled.png"
import numeral from "numeral"
import {useEffect, useState} from "react"
import {Loading} from "../../common/Loading";
import * as CommonUtil from "../../common/CommonUtil"
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";

const Home = (props) => {
    const [loading, setLoading] = useState(false)
    const [payList, setPayList] = useState([
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, payAmount: 0},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, payAmount: 0},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, payAmount: 0}
    ])
    const [userInfo, setUserInfo] = useState({
        amount: 0
    })

    useEffect(() => {
        console.log("### HomeScreen ###")
    }, [])

    const onRefresh = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-5 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}></span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col h-[280px] p-5 gap-5 rounded-lg bg-gradient-to-r from-[#FF7340] to-[#F64332]"}>
                    <div>
                        <span className={"text-white text-lg font-bold"}>
                            총 포인트 현황
                        </span>
                    </div>
                    <div className={"flex-grow"}>
                        <img className={"w-5 h-5"} src={icon_refresh} alt={""} onClick={onRefresh}/>
                    </div>
                    <div className={"flex justify-end"}>
                        <span className={"text-white text-3xl font-bold text-right"}>
                            ₩ {numeral(userInfo.amount).format("0,0")}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"h-0.5 bg-gray-200 my-6"}/>
            <div className={"flex flex-col pl-5 gap-2"}>
                <span className={"text-lg font-bold"}>
                    포인트
                </span>

                {/* 결제수단 목록 */}
                <div className={"flex flex-row gap-4 overflow-x-auto"}>
                    {payList.map((item, idx) => {
                        return (
                            <div key={String(idx)}
                                 className={`w-[300px] h-[150px] flex flex-col ${CommonUtil.getBackgroundColor(item.payType)} border-1 rounded-lg p-4 mr-4`}>
                                <div className={"flex flex-row items-center"}>
                                    <img className={"w-8 h-8"} src={item.logo} alt={""}/>
                                    <span className={"w-[300px] text-white text-xl font-bold"}>{item.title}</span>
                                </div>
                                <span className={"text-end text-white text-xl font-bold"}>{numeral(item.payAmount).format("0,0")}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(Home)