import {useEffect, useState} from "react";
import * as CommonUtil from "../../common/CommonUtil";
import numeral from "numeral";
import Frame from "../main/Frame";

const PointSendStep01 = (props) => {
    const [loading, setLoading] = useState(false)
    const [sendInfo, setSendInfo] = useState(
        {pointType: "", amount: 0, memo: "", mobileNo: ""}
    )
    const payList = CommonUtil.getPayList()

    useEffect(() => {
        console.log("### PointSendStep01 ###")
    }, [])

    return (
        <Frame>
            <div className={"flex flex-col gap-4 p-5"}>
                <div className={"flex flex-row border-b-2 border-gray-200 p-1 justify-between"}>
                    <input className={"bg-transparent"} placeholder={"포인트선택"} disabled={true}/>
                    <select onChange={e => {
                        setSendInfo((state) => ({
                            ...state,
                            pointType: e.target.value
                        }))
                    }}>
                        {payList.map((item, idx) => {
                            return <option key={String(idx)} value={item.title}>{item.title}</option>
                        })}
                    </select>
                </div>
                <div className={"flex flex-row border-b-2 border-gray-200 p-1"}>
                    <input className={"bg-transparent"} placeholder={"금액"}
                           type={"number"}
                           onChange={e => {
                               setSendInfo((state) => ({
                                   ...state, amount: Number(CommonUtil.removeComma(e.target.value))
                               }))
                           }}
                           value={sendInfo.amount}/>
                </div>
                <div className={"flex flex-row border-b-2 border-gray-200 p-1"}>
                    <input className={"bg-transparent"} placeholder={"메모"}/>
                </div>
                <div className={"flex flex-row border-b-2 border-gray-200 p-1"}>
                    <input className={"bg-transparent"} placeholder={"휴대폰번호"}/>
                </div>
            </div>
            <div className={"flex flex-row p-5 items-center justify-between"}>
                <span className={"text-lg font-bold"}>전송금액</span>
                <div className={"flex flex-row gap-2"}>
                    <span className={"text-2xl font-bold"}>{numeral(sendInfo.amount).format("0,0")}</span>
                    <span className={"text-2xl font-bold"}>{sendInfo.pointType}</span>
                </div>
            </div>
            <div className={"flex m-5 h-[56px] rounded-lg bg-[#3183ed] items-center justify-center"}>
                <span className={"text-white text-xl font-bold"}>전송요청</span>
            </div>
        </Frame>
    )
}

export default PointSendStep01