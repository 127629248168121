import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import Frame from "../main/Frame";
import {useEffect, useState} from "react";
import numeral from "numeral";
import icon_w3p_logo from "../../assets/svg/logo.svg";
import icon_lpoint from "../../assets/icon_lpoint.png";
import icon_disabled from "../../assets/icon_disabled.png";
import icon_checked from "../../assets/svg/icon_checked.svg"
import icon_uncheck from "../../assets/svg/icon_uncheck.svg"
import * as CommonUtil from "../../common/CommonUtil";

const PayScreen = (props) => {
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        amount: 0
    })
    const [payList, setPayList] = useState([
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, payAmount: 0, checked: false},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, payAmount: 0, checked: false},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, payAmount: 0, checked: false}
    ])

    useEffect(() => {
        console.log("### PayScreen ###")
    }, [])

    const toggleCheck = (item) => {

    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-5 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>포인트로 결제하기</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col p-5 gap-2 rounded-lg bg-gradient-to-r from-[#FF7340] to-[#F64332]"}>
                    <span className={"text-white text-lg font-bold"}>
                        총 포인트 보유 현황
                    </span>
                    <span className={"text-white text-3xl font-bold text-right"}>
                        ₩ {numeral(userInfo.amount).format("0,0")}
                    </span>
                </div>
            </div>
            <div className={"h-0.5 bg-gray-200 my-4"}/>
            <div className={"flex flex-col px-5 gap-4"}>
                {payList.map((item, idx) => {
                    return (
                        <div key={String(idx)}
                             className={`flex flex-row ${CommonUtil.getBackgroundColor(item.payType)} border-1 rounded-lg p-4 justify-between`}>
                            <div className={"flex flex-row gap-2 items-center"}>
                                <img className={"w-8 h-8"} src={item.checked ? icon_checked : icon_uncheck} alt={""} onClick={() => toggleCheck(item)}/>
                                <img className={"w-8 h-8"} src={item.logo} alt={""}/>
                                <span className={"text-white text-xl font-bold"}>{item.title}</span>
                            </div>
                            <span className={"text-white text-xl font-bold"}>{numeral(item.payAmount).format("0,0")}</span>
                        </div>
                    )
                })}
                <div className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#FF7340] to-[#F64332] items-center"}>
                    <span className={"text-3xl text-white font-bold"}>
                        포인트 결제하기
                    </span>
                </div>
            </div>
        </Frame>
    )
}

export default PayScreen