import icon_home from '../assets/svg/icon_home.svg'
import icon_send from '../assets/svg/icon_send.svg'
import icon_history from '../assets/svg/icon_history.svg'
import icon_card from '../assets/svg/icon_card.svg'
import icon_pay from '../assets/svg/icon_pay.svg'
import {useNavigate} from "react-router-dom";

const GlobalBottom = (props) => {
    const navigate = useNavigate();

    const goPage = (target) => {
        navigate("/" + target)
    }

    return (
        <div className={"flex flex-row justify-around px-2 fixed w-[390px] h-[78px] bottom-0 bg-white rounded-t-3xl shadow-md"}>
            <div className={"flex flex-col items-center justify-center gap-2"} onClick={() => goPage("send")}>
                <img src={icon_send} alt={""}/>
                <span className={"text-xs text-gray-500"}>포인트전송</span>
            </div>
            <div className={"flex flex-col items-center justify-center gap-2"} onClick={() => goPage("history")}>
                <img src={icon_history} alt={""}/>
                <span className={"text-xs text-gray-500"}>적립내역</span>
            </div>
            <div className={"flex flex-col items-center justify-center"} onClick={() => goPage("home")}>
                <img src={icon_home} alt={""}/>
            </div>
            <div className={"flex flex-col items-center justify-center gap-2"} onClick={() => goPage("card/status")}>
                <img src={icon_card} alt={""}/>
                <span className={"text-xs text-gray-500"}>{"하나법인\r\n체크카드"}</span>
            </div>
            <div className={"flex flex-col items-center justify-center gap-2"} onClick={() => goPage("pay")}>
                <img src={icon_pay} alt={""}/>
                <span className={"text-xs text-gray-500"}>결제</span>
            </div>
        </div>
    )
}

export default GlobalBottom