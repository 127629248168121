import Frame from "../main/Frame";
import {useEffect, useState} from "react";
import {Loading} from "../../common/Loading";
import numeral from "numeral";
import icon_w3p_logo from "../../assets/svg/logo.svg";
import icon_lpoint from "../../assets/icon_lpoint.png";
import icon_disabled from "../../assets/icon_disabled.png";

const PointHistory = (props) => {
    const temp = [
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, point: 500, instTime: "2024-10-01\n15:39:00"},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, point: 500, instTime: "2024-10-01\n15:39:00"},
    ]

    const [loading, setLoading] = useState(false)
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        console.log("### PointHistory ###")

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setDataList(temp)
        }, 2000)

    }, [])

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex-grow flex-col bg-[#878787] rounded-lg shadow-md m-5 p-5"}>
                <div className={"flex flex-row justify-between"}>
                    <span className={"text-white font-bold"}>포인트명</span>
                    <span className={"text-white font-bold"}>적립일시</span>
                    <span className={"text-white font-bold"}>적립포인트</span>
                </div>
                <div className={"overflow-y-auto"}>
                    {dataList.map((item, idx) => {
                        return (
                            <div className={"flex flex-row border-b-2 border-gray-400 items-center justify-between p-2"}>
                                <img className={"w-6 h-6"} src={item.logo} alt={""}/>
                                <span className={"text-white text-center"}>{item.instTime}</span>
                                <span className={"text-white"}>{numeral(item.point).format("0,0")}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Frame>
    )
}

export default PointHistory