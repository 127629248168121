// export const hanaCardHost = "https://13.125.180.115:9142"
import {HmacSHA256} from "crypto-js";
import axios from "axios";

const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";
export const hanaCardHost = "https://hana-interface.com:9142"
// export const hanaCardHost = "https://web3pays.net/api/hana"
// export const hanaCardHost = proxy + "https://13.125.180.115:9142"

const ownerRegNo = "6598802021"
const memberNum = "00003"
const FAPI_key = "e6796496305a4424b6fad4445326af0f"
const FSecretKey = "f30a22fe99ac433b95e6a253c1b06f26"

function unixTimeInMilliseconds() {
    return String(Date.now())
}

function unixTimeInSeconds() {
    return String(Math.floor(Date.now() / 1000))
}

async function generateHMAC(message) {
    const encoder = new TextEncoder()

    const key = await window.crypto.subtle.importKey(
        'raw',
        encoder.encode(FSecretKey),
        { name: 'HMAC', hash: { name: 'SHA-256'}},
        false,
        ['sign']
    )

    const signature = await window.crypto.subtle.sign(
        'HMAC',
        key,
        encoder.encode(message)
    )

    return Array.from(new Uint8Array(signature))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
        .toLowerCase()
}

function sendHanaServer(request, body) {
    const orderTime = unixTimeInMilliseconds()
    const param = orderTime + FAPI_key
    const sign = HmacSHA256(param, FSecretKey).toString()

    const headers = {
        "HANA-APIKEY": FAPI_key,
        "HANA-TS": orderTime,
        "HANA-SIGN": sign
    }

    body.owner_reg_no = ownerRegNo
    body.member_num = memberNum

    console.log("### header :: ", headers)
    console.log("### request :: ", request)
    console.log("### body :: ", body)

    return axios.post(request, body, {
        headers: headers
    })
}

export function HCCardInfo(body) {
    const request = hanaCardHost + "/get_card_info"
    return sendHanaServer(request, body)
}

export function HCRegister(body) {
    const request = hanaCardHost + "/join_member"
    return sendHanaServer(request, body)
}

export function HCGetUserInfoCashSum(body) {
    const request = hanaCardHost + "/get_user_info_cash_sum"
    return sendHanaServer(request, body)
}

export function HCBalanceCardUserInfo(body) {
    const request = hanaCardHost + "/balance_card_user_info"
    return sendHanaServer(request, body)
}

export function HCTransactionHistory(body) {
    const request = hanaCardHost + "/get_transaction_history"
    return sendHanaServer(request, body)
}

export function HCDeposit(body) {
    const request = hanaCardHost + "/deposit_point"
    return sendHanaServer(request, body)
}

export function HCChangeLimit(body) {
    const request = hanaCardHost + "/change_limit"
    return sendHanaServer(request, body)
}