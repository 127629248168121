import useModal from "../hooks/useModal";
import {useEffect} from "react";
import {connect, useSelector} from "react-redux";

const Alert = (props) => {
    const {Modal, open, close} = useModal();

    useEffect(() => {
        if (props.open) {
            open();
        }
    })

    const onClose = () => {
        close();
        props.onClose();
    }

    return (
        <Modal>
            <div className={"flex flex-col w-[390px] py-[30px] bg-black rounded-xl p-4"}>
                <p className={"text-white text-center text-2xl font-bold"}>{props.title}</p>
                <p className={"text-white text-xl font-bold mt-8"}>{props.content}</p>
                <button className={"flex-1 rounded-xl bg-white mt-8 p-2 text-2xl font-bold"} onClick={onClose}>
                    확 인
                </button>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { store, order, printer } = state;
    return {
        store, order, printer
    }
}

export default connect(mapStateToProps, null)(Alert);