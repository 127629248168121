import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import {connect, useSelector} from "react-redux";
import * as api from "../../api/api"
import * as hanaApi from "../../api/hanaApi"
import * as CommonUtil from "../../common/CommonUtil";
import {useNavigate} from "react-router-dom";

const CardStatus = (props) => {
    const {user} = useSelector((props) => props.account);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [userCard, setUserCard] = useState({
        userId: "", cardNo: ""
    })
    const [cashPoint, setCashPoint] = useState({})
    const [balancePoint, setBalancePoint] = useState({})
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        const params = {
            cardNo: user.cardNo,
            isUse: 1
        }

        setLoading(true)
        api.getUserCard(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    setUserCard(data)
                    getCardTransactionHistory(data.cardNo)
                }
                else {
                    // 등록 카드가 없으면 등록 페이지로
                    navigate("/card/register")
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const getCashPoint = () => {
        const body = {
            card_num: user.cardNo
        }

        hanaApi.HCGetUserInfoCashSum(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setCashPoint(data[0])
                }
                getBalancePoint()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const getBalancePoint = () => {
        const body = {
            card_num: user.cardNo,
            tel_no : ""
        }

        hanaApi.HCBalanceCardUserInfo(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setBalancePoint(data[0])
                }
                setLoading(false)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const getCardTransactionHistory = (cardNo) => {
        const body = {
            card_num: user.cardNo,
            from_site_date: dayjs().add(-3, "d").format("YYYYMMDD"),
            to_site_date: dayjs().add(-1, "d").format("YYYYMMDD"),
            state: ""
        }

        setLoading(true)
        hanaApi.HCTransactionHistory(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDataList(data)

                getCashPoint()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>하나법인체크카드 등록하기</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"w-[360px] h-[214px] bg-[url('./assets/img_card.png')] flex flex-col py-5"}>
                    <div className={"flex flex-row items-center justify-between px-8"}>
                        <span className={"text-white font-bold"}>캐시포인트 잔액</span>
                        <span className={"text-white font-bold"}>{numeral(cashPoint.cash_amount).format("0,0")}</span>
                    </div>
                    <div className={"flex flex-row items-center justify-between px-8"}>
                        <span className={"text-white font-bold"}>카드포인트 잔액</span>
                        <span className={"text-white font-bold"}>{numeral(balancePoint.balance).format("0,0")}</span>
                    </div>
                    <div className={"h-[45px]"} />
                    <div className={"flex flex-col items-center justify-center"}>
                        <span className={"text-white text-2xl font-bold"}>
                            {CommonUtil.regexpCardNumber(userCard.cardNo)}
                        </span>
                    </div>
                </div>
                <div className={"flex flex-row items-center justify-between gap-2"}>
                    <button className={"flex-1 rounded-lg bg-[#878787] shadow-md text-lg text-white font-bold p-4"}
                            onClick={() => {
                                navigate("/card/charge")
                            }}>
                        충전
                    </button>
                    <button className={"flex-1 rounded-lg bg-[#878787] shadow-md text-lg text-white font-bold p-4"}
                            onClick={() => {
                                navigate("/card/transfer")
                            }}>
                        이체
                    </button>
                    <button className={"flex-1 rounded-lg bg-[#878787] shadow-md text-lg text-white font-bold p-4"}>
                        분실신고
                    </button>
                </div>
                <div className={"flex-grow flex-col bg-[#878787] rounded-lg shadow-md p-5"}>
                    <div className={"flex flex-row justify-between"}>
                        <span className={"text-white font-bold"}>사용처</span>
                        <span className={"text-white font-bold"}>사용일시</span>
                        <span className={"text-white font-bold"}>사용포인트</span>
                    </div>
                    <div className={"overflow-y-auto"}>
                        {dataList.map((item, idx) => {
                            return (
                                <div className={"flex flex-row border-b-2 border-gray-400 items-center justify-between p-2"}>
                                    <img className={"w-6 h-6"} src={item.logo} alt={""}/>
                                    <span className={"text-white text-center"}>{item.instTime}</span>
                                    <span className={"text-white"}>{numeral(item.point).format("0,0")}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <button className={"text-sm text-right text-black font-bold"} onClick={() => {
                    navigate("/card/history")
                }}>
                    더보기
                </button>
            </div>
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardStatus)