import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import * as hanaApi from "../../api/hanaApi"
import * as api from "../../api/api";
import useModal from "../../hooks/useModal";
import Alert from "../../common/Alert";

const CardCharge = (props) => {
    const {user} = useSelector((props) => props.account);

    const [isAlert, setIsAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userCard, setUserCard] = useState({})
    const [cardInfo, setCardInfo] = useState({
        chargeDiv: "V", chargePoint: "Cash", accountOwner: "", amount: ""
    })

    const {Modal, open, close} = useModal();

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        const params = {
            cardNo: user.cardNo,
            isUse: 1
        }

        setLoading(true)
        api.getUserCard(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    setUserCard(data)
                    console.log("### data :: ", data)
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const request = () => {
        if (window.confirm("충전을 요청하시겠어요?")) {
            console.log("### cardInfo :: ", cardInfo)
            if (cardInfo.chargeDiv === "V") {
                // 가상계좌
                virtual()
            }
            else if (cardInfo.chargeDiv === "D") {
                // 무통장입금
                deposit()
            }
        }
    }

    const virtual = () => {
        window.alert("### PG 연결은 준비중입니다.")
        return
    }

    const deposit = () => {
        const body = {
            card_num: userCard.cardNo,
            input_kind: "무통장 입금",
            cash_amount: String(cardInfo.amount),
            state: "",
            cash_id: ""
        }

        if (cardInfo.chargePoint === 'Cash') {
            setLoading(true)
            hanaApi.HCDeposit(body).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    putDeposit(body)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                    setLoading(false)
                })
        }
        else {
            putDeposit(body)
        }
    }

    const putDeposit = (body) => {
        const params = {
            cardNo: body.card_num,
            depositDiv: cardInfo.chargeDiv,
            pointDiv: cardInfo.chargePoint,
            amount: cardInfo.amount,
            type: "I"
        }

        api.putDeposit(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                console.log("### data :: ", data)
                setIsAlert(true)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>하나법인체크카드 충전</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col bg-[#505050] rounded-lg shadow-md px-4 py-2 gap-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>캐시포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>0</span>
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>카드포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>0</span>
                    </div>
                </div>
                <div className={"flex flex-col bg-[#878787] rounded-lg shadow-md p-5 gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>충전수단</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                setCardInfo((state) => ({
                                    ...state,
                                    chargeDiv: e.target.value
                                }))
                        }}>
                            <option value={"V"}>가상계좌</option>
                            <option value={"D"}>무통장입금</option>
                        </select>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>충전대상 포인트 선택</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                    setCardInfo((state) => ({
                                        ...state,
                                        chargePoint: e.target.value
                                    }))
                                }}>
                            <option value={"Cash"}>캐시포인트</option>
                            <option value={"Card"}>카드포인트</option>
                        </select>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>입금정보</span>
                        <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, accountOwner: e.target.value
                                   }))
                               }}
                               value={cardInfo.accountOwner}/>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>입금액</span>
                        <input className={"bg-transparent text-white font-bold text-lg"} placeholder={""}
                               type={"number"}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, amount: Number(e.target.value)
                                   }))
                               }}
                               value={cardInfo.amount}/>
                    </div>
                </div>
                <button className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#717171] to-[#D9C7C6] items-center text-3xl text-white font-bold"}
                        onClick={request}>
                    충 전 요 청
                </button>
                <div className={"h-[80px]"} />
            </div>
            <Alert open={isAlert} title={"Alert!"}
                   content={"요청이 관리자에게 접수되었습니다.\n관리자 승인까지는 시간이 소요될 수 있습니다."}
                   onClose={() => {
                       setIsAlert(false);
                   }} />
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardCharge)