import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import * as hanaApi from "../../api/hanaApi";
import numeral from 'numeral'
import {connect, useSelector} from "react-redux";

const CardTransfer = (props) => {
    const {user} = useSelector((props) => props.account);

    const [loading, setLoading] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const [balance, setBalance] = useState([])
    const [cardInfo, setCardInfo] = useState({
        transferDiv: "C", targetNo: "", accountName: "", amount: ""
    })

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        const body = {
            card_num: user.cardNo,
            tel_no: ""
        }

        hanaApi.HCBalanceCardUserInfo(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                console.log("### balance :: ", data)
                setBalance(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const request = () => {
        if (cardInfo.transferDiv === "C") {
            cardTransfer()
        }
        else if (cardInfo.transferDiv === "B") {
            accountTransfer()
        }
    }

    const accountTransfer = () => {
        window.alert("계좌 이체 거래는 현재 준비중입니다. (PG 연동)")
    }

    const cardTransfer = () => {
        if (window.confirm(cardInfo.targetNo + "로의 이체를 요청하시겠어요?")) {
            // 1. 내 balance 에서 차감
            deducted()
        }
    }

    const deducted = () => {
        const body = {
            card_num: user.cardNo,
            day_limit: String(cardInfo.amount * -1),
            mon_limit: String(cardInfo.amount * -1),
            one_dollar_limit: String(cardInfo.amount * -1),
            mon_dollar_limit: String(cardInfo.amount * -1),
        }

        hanaApi.HCChangeLimit(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                // 2. 송금한다
                remit()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const remit = () => {
        const body = {
            card_num: cardInfo.targetNo,
            day_limit: String(cardInfo.amount),
            mon_limit: String(cardInfo.amount),
            one_dollar_limit: String(cardInfo.amount),
            mon_dollar_limit: String(cardInfo.amount),
        }

        hanaApi.HCChangeLimit(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("처리가 완료되었습니다.")
                search()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>하나법인체크카드 이체</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col bg-[#505050] rounded-lg shadow-md px-4 py-2 gap-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-center text-white text-sm font-bold"}>{"이체가능\n캐시포인트"}</span>
                        <span className={"text-white text-lg font-bold"}>{balance.length > 0 ? numeral(balance[0].balance).format("0,0") : 0}</span>
                    </div>
                </div>
                <div className={"flex flex-col bg-[#878787] rounded-lg shadow-md p-5 gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>이체방식</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                setCardInfo((state) => ({
                                    ...state,
                                    transferDiv: e.target.value
                                }))
                        }}>
                            <option value={"C"}>카드번호</option>
                            <option value={"B"}>외부통장</option>
                        </select>
                    </div>
                    {cardInfo.transferDiv === 'C' &&
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>카드번호</span>
                            <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                                   onChange={e => {
                                       setCardInfo((state) => ({
                                           ...state, targetNo: e.target.value
                                       }))
                                   }}
                                   value={cardInfo.targetNo}/>
                        </div>
                    </div>
                    }
                    {cardInfo.transferDiv === 'B' &&
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>계좌번호</span>
                            <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                                   onChange={e => {
                                       setCardInfo((state) => ({
                                           ...state, targetNo: e.target.value
                                       }))
                                   }}
                                   value={cardInfo.targetNo}/>
                        </div>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>카드번호</span>
                            <select className={"bg-transparent text-white text-xl font-bold"}
                                    onChange={e => {
                                        setCardInfo((state) => ({
                                            ...state,
                                            accountName: e.target.value
                                        }))
                                    }}>
                                <option value={"C"}>국민은행</option>
                                <option value={"B"}>신한은행</option>
                                <option value={"B"}>우리은행</option>
                                <option value={"B"}>농협은행</option>
                            </select>
                        </div>
                    </div>
                    }
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>이체 포인트</span>
                        <input className={"bg-transparent text-white font-bold text-lg"} placeholder={""}
                               type={"number"}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, amount: Number(e.target.value)
                                   }))
                               }}
                               value={cardInfo.amount}/>
                    </div>
                </div>
                <button className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#717171] to-[#D9C7C6] items-center text-3xl text-white font-bold"}
                        onClick={request}>
                    이 체 요 청
                </button>
                <div className={"h-[80px]"} />
            </div>
            {/*<Alert open={isAlert} title={"Alert!"}*/}
            {/*       content={"요청이 관리자에게 접수되었습니다.\n관리자 승인까지는 시간이 소요될 수 있습니다."}*/}
            {/*       onClose={() => {*/}
            {/*           setIsAlert(false);*/}
            {/*       }} />*/}
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardTransfer)